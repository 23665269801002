import { i18next } from '@repo/translation/i18next';
import { initReactI18next } from '@repo/translation/react-i18next';
import resources from './resources.json';
import { getFromLocalStorage } from '@repo/utils/localStorageUtil';
import { USER_LANGUAGE } from './constants.ts';

export function initTranslation() {
  const defaultLang =
    typeof window === 'undefined' ? 'en' : (getFromLocalStorage(USER_LANGUAGE) as string);

  i18next.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    lng: defaultLang || 'en',
  });
}
